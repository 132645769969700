<template>
  <v-container fluid class="pa-0 cbg" v-resize="innercal">
    <v-img v-if="logook" :src="require('@/assets/ldp3.png')">
      <v-btn icon elevation="0" fab absolute right class="mt-n1 mr-n4"
        ><v-app-bar-nav-icon
          v-if="logook"
          @click="handle_drawer()"
        ></v-app-bar-nav-icon
      ></v-btn>
    </v-img>
    <div class="d-flex flex-wrap justify-center pa-1">
      <!-- <v-card
        v-for="item in flexmenu"
        :key="item.index"
        :color="item.color"
        :width="width3"
        :to="item.to"
        elevation="0"
        class="ma-1"
      >
        <v-card-text class="text-h5 font-weight-bold text-center">
          <v-img :src="require(`@/assets/${item.bt}.svg`)"></v-img>
        </v-card-text>
        <v-card-actions
          ><div class="cardtitle text-center">
            {{ item.title }}
          </div>
        </v-card-actions>
      </v-card> -->

      <v-card color="fff" :width="width3" to="db" elevation="0" class="ma-1">
        <v-card-text class="text-h5 font-weight-bold text-center">
          <v-img :src="require(`@/assets/btn01.svg`)"></v-img>
        </v-card-text>
        <v-card-actions
          ><div class="cardtitle text-center">ฐานข้อมูล</div>
        </v-card-actions>
      </v-card>

      <v-card
        color="fff"
        :width="width3"
        :to="'checkmenu'"
        elevation="0"
        class="ma-1"
      >
        <v-card-text class="text-h5 font-weight-bold text-center">
          <v-img :src="require(`@/assets/btn02.svg`)"></v-img>
        </v-card-text>
        <v-card-actions
          ><div class="cardtitle text-center">ตรวจวาระจิต</div>
        </v-card-actions>
      </v-card>

      <v-card
        color="fff"
        :width="width3"
        to="report"
        elevation="0"
        class="ma-1"
      >
        <v-card-text class="text-h5 font-weight-bold text-center">
          <v-img :src="require(`@/assets/btn03.svg`)"></v-img>
        </v-card-text>
        <v-card-actions
          ><div class="cardtitle text-center">จำนวนนักศึกษา</div>
        </v-card-actions>
      </v-card>
    </div>
    <div class="copyright text-center">
      <v-icon class="mr-2"> mdi-database </v-icon> งานทะเบียน
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    flexmenu: [
      { id: 1, title: "ฐานข้อมูล", bt: "btn01", color: "#fff", to: "db" },
      {
        id: 2,
        title: "ตรวจวาระจิต",
        bt: "btn02",
        color: "#fff",
        to: "checkmenu",
      },
      { id: 3, title: "รายงาน", bt: "btn03", color: "#fff", to: "report" },
    ],
    logook: false,
  }),
  computed: {
    // winheight() {
    //   return window.innerHeight;
    // },
    // winwidth() {
    //   return window.innerWidth;
    // },
    width3() {
      let re = 0;
      if (this.$vuetify.breakpoint.xs) {
        re = window.innerWidth / 3 - 15;
      } else if (this.$vuetify.breakpoint.sm) {
        re = window.innerWidth / 3 - 50;
      } else {
        re = window.innerWidth / 3 - 100;
      }
      return re;
    },

    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
  },
  methods: {
    innercal() {
      if (window.innerHeight / window.innerWidth > 1.5) {
        this.logook = true;
      } else {
        this.logook = false;
      }
      // this.width3();
    },
    handle_drawer() {
      this.global.drawer = !this.global.drawer;
    },
  },
};
</script>

<style>
.cbg {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(236, 229, 229) 40%,
    rgb(255, 255, 255) 100%
  );
  /* height: 100vh; */
  height: 100%;
}

.disable-events {
  pointer-events: none;
}

.cardtitle {
  width: 100%;
  background-color: transparent;
  font-size: 1rem;
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  height: 40px;
  font-size: 1.2rem;
}

.maindiv {
  border: 1px solid red;
}
</style>


