<template>
  <div class="centerlogin bgbg">
    <v-snackbar
      :timeout="3000"
      v-model="alert_message_enable"
      color="pink darken-3"
      bottom
      rounded="pill"
      outlined
    >
      <div class="body-1">{{ alert_message }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink darken-3"
          icon
          small
          v-bind="attrs"
          @click="alert_message_enable = false"
        >
          <v-icon>mdi-close-box</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card maix-width="400" elevation="0" class="pa-5 transparent">
      <v-card-title>
        <v-img :src="require('@/assets/logo.svg')"></v-img>
      </v-card-title>

      <v-card-text class="mt-10">
        <v-text-field
          v-model="username_input"
          label="ชื่อผู้ใช้งาน"
          prepend-icon="mdi-account-circle"
          @keyup.enter="actionlogin()"
        />
        <v-text-field
          v-model="password_input"
          :type="show_password ? 'text' : 'password'"
          label="รหัสผ่าน"
          prepend-icon="mdi-lock"
          :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show_password = !show_password"
          @keyup.enter="actionlogin()"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mx-auto"
          outlined
          color="grey darken-2"
          @click="actionlogin()"
        >
          เข้าสู่ระบบ
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    alert_message: "",
    alert_message_enable: false,
    show_password: false,
    username_input: "",
    password_input: "",
    // login_mode: "token",
  }),
  methods: {
    set_user_state(login_result) {
      this.user_info.is_login = true;
      this.user_info.username = login_result.username;
      this.user_info.role = login_result.role;
      this.user_info.room = login_result.room;
      this.query_option.status_tick = login_result.status_tick;
      // this.user_info.first_name = login_result.first_name;
      // this.user_info.last_name = login_result.last_name;
      // this.user_info.position = login_result.position;
      // this.user_info.jsjid = login_result.jsjid;
      // this.user_info.token = login_result.token;
      localStorage.token = login_result.token;
    },
    async actionlogin() {
      let login_result = await axios.post(this.authen_url, {
        email: this.username_input,
        password: this.password_input,
      });
      if (login_result.data.token) {
        this.set_user_state(login_result.data);
        window.location.reload();
        // if (this.$route.name == "home") {
        //   await window.location.reload();
        // } else {
        //   await this.$router.replace({ path: "/" });
        // }
      } else if (login_result.data.message == "1") {
        this.alert_message = await "จำนวนเครื่องที่ login มากกว่าที่กำหนด";
        this.alert_message_enable = await true;
      } else {
        this.alert_message = await "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง";
        this.alert_message_enable = await true;
      }
    },
  },
  computed: {
    authen_url: function () {
      return `${this.$store.state.apiurl}/api/login`;
    },
    current_url: function () {
      return `${this.$store.state.apiurl}/api/current`;
    },
    user_info: {
      get() {
        return this.$store.getters.get_user_info;
      },
      set(value) {
        this.$store.dispatch("handle_user_info", {
          userInfo: value,
        });
      },
    },
    query_option: {
      get() {
        return this.$store.getters.get_query_option;
      },
      set(value) {
        this.$store.dispatch("handle_query_option", {
          query_option: value,
        });
      },
    },
  },
};
</script>

<style scoped>
.centerlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bgbg {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 50%,
    rgb(130, 183, 209) 70%,
    rgb(12, 123, 178) 100%
  );
}
</style>
