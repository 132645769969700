import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JsonExcel from "vue-json-excel";

const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

Vue.component("downloadExcel", JsonExcel);
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')