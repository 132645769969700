import Vue from "vue";
import VueRouter from "vue-router";
// import Vuex from 'vuex'
import home from "../views/home.vue";

// Vue.use(Vuex)
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  },
  {
    path: "/db",
    name: "db",
    component: () => import("../views/db.vue")
  },
  {
    path: "/report",
    name: "report",
    component: () => import("../views/report.vue")
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/setting.vue")
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user.vue")
  },
  {
    path: "/import",
    name: "import",
    component: () => import("../views/import.vue")
  },
  {
    path: "/upimg",
    name: "upimg",
    component: () => import("../views/upimg.vue")
  },
  {
    path: "/checkmenu",
    name: "checkmenu",
    component: () => import("../views/checkmenu.vue")
  },
  {
    path: "/checkroom/:room",
    name: "checkroom",
    component: () => import("../views/checkroom.vue")
  },
  {
    path: "/gotonext",
    name: "gotonext",
    component: () => import("../views/gotonext.vue")
  },
  {
    path: "/token",
    name: "token",
    component: () => import("../views/token.vue")
  },
  {
    path: "/datagrid",
    name: "datagrid",
    component: () => import("../views/datagrid.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
