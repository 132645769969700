<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
      scrollable
    >
      <!-- <v-dialog v-model="dialog" persistent max-width="1200px"> -->
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="updatePerson"
        :readonly="user_info.frm_userdetail == 0 ? true : false"
      >
        <v-card>
          <!-- <v-toolbar dark color="primary" dense >
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ mode_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar> -->

          <v-card-actions class="grey lighten-2">
            <v-btn icon disabled></v-btn>
            <v-spacer></v-spacer>
            <div class="text-h6 blue-grey--text">
              {{ mode_title }}
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-card-actions>

          <v-card-text id="scrolling-techniques">
            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                <v-card elevation="0">
                  <!-- max-height="450" -->
                  <v-card-actions max-width="280" class="justify-center">
                    <!-- max-height="450" -->
                    <v-img :src="currentPreviewImg" max-width="280"></v-img>
                  </v-card-actions>
                </v-card>
                <v-card elevation="0">
                  <v-card-actions class="justify-center">
                    <v-file-input
                      :disabled="user_info.frm_userdetail == 0 ? true : false"
                      label="เลือกภาพ"
                      prepend-icon="mdi-camera"
                      v-model="file"
                      accept="image/jpeg"
                      @change="preview_image"
                      @click:clear="backToOldImage"
                    ></v-file-input>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="8" lg="7" xl="7">
                <v-row class="pa-0">
                  <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model="personInForm.pname"
                      :rules="valid30"
                      :counter="30"
                      label="คำนำหน้า"
                      outline
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                      v-model="personInForm.fname"
                      :rules="valid255"
                      :counter="255"
                      label="ชื่อ"
                      outline
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                      v-model="personInForm.lname"
                      :rules="valid255"
                      :counter="255"
                      label="สกุล"
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model="personInForm.year_of_birth"
                      :rules="valid4"
                      :counter="4"
                      label="ปีเกิด"
                      required
                      outline
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                      v-model="personInForm.province"
                      :rules="valid30"
                      :counter="30"
                      label="จังหวัด"
                      required
                      outline
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                      v-model="personInForm.occupation"
                      :rules="valid255"
                      :counter="255"
                      label="อาชีพ"
                      required
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model="personInForm.jsjid"
                      :rules="validjsjid"
                      :counter="19"
                      :error-messages="jsjidErrorsMessage"
                      label="รหัสนักศึกษา"
                      required
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                    <v-select
                      :items="$store.state.user_info.room"
                      v-model="personInForm.class"
                      :rules="valid10"
                      :counter="4"
                      label="ห้องเรียน"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <v-select
                      :items="global.status_full"
                      v-model="personInForm.status"
                      :rules="valid50"
                      :counter="50"
                      label="สถานะ"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model="personInForm.line"
                      :rules="valid50"
                      :counter="50"
                      label="ชื่อไลน์"
                      required
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="personInForm.phone_number"
                      :rules="valid50"
                      :counter="50"
                      label="หมายเลขโทรศัพท์"
                      required
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5">
                    <!-- <v-select
                      :items="global.know_from_full"
                      v-model="personInForm.know_from"
                      :rules="valid100"
                      :counter="100"
                      label="รู้จักมูลนิธิโดย"
                    ></v-select> -->
                    <v-text-field
                      v-model="personInForm.know_from"
                      label="รู้จักมูลนิธิโดย"
                      :rules="valid255"
                      :counter="255"
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <!-- <v-select
                      :items="global.teacher_full"
                      v-model="personInForm.teacher1"
                      :rules="valid100"
                      :counter="100"
                      label="อาจารย์ 1"
                    ></v-select> -->
                    <v-text-field
                      v-model="personInForm.teacher1"
                      label="อาจารย์ 1"
                      :rules="valid255"
                      :counter="255"
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <!-- <v-select
                      :items="global.teacher_full"
                      v-model="personInForm.teacher2"
                      :rules="valid100"
                      :counter="100"
                      label="อาจารย์ 2"
                    ></v-select> -->
                    <v-text-field
                      v-model="personInForm.teacher2"
                      label="อาจารย์ 2"
                      :rules="valid255"
                      :counter="255"
                      outline
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="personInForm.remark"
                      label="remark"
                      :rules="valid255"
                      :counter="255"
                      required
                      outline
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="2" xl="2">
                <v-sheet
                  :rounded="true"
                  color="grey lighten-3"
                  outlined
                  class="pa-5"
                >
                  <v-row>
                    <v-text-field
                      v-model="personInForm.room1"
                      label="วันที่เข้ามูลนิธิ"
                      outline
                      prepend-icon="mdi-numeric-1-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>

                    <v-text-field
                      v-model="personInForm.room2"
                      label="เข้าห้อง 2"
                      outline
                      prepend-icon="mdi-numeric-2-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room3"
                      label="เข้าห้อง 3"
                      outline
                      prepend-icon="mdi-numeric-3-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room4"
                      label="เข้าห้อง 4"
                      outline
                      prepend-icon="mdi-numeric-4-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room5"
                      label="เข้าห้อง 5"
                      outline
                      prepend-icon="mdi-numeric-5-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room6"
                      label="เข้าห้อง 6"
                      outline
                      prepend-icon="mdi-numeric-6-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room7"
                      label="เข้าห้อง 7"
                      outline
                      prepend-icon="mdi-numeric-7-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room71"
                      label="เข้าห้อง 7.10"
                      outline
                      prepend-icon="mdi-numeric-7-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room8"
                      label="เข้าห้อง 8"
                      outline
                      prepend-icon="mdi-numeric-8-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room9"
                      label="เข้าห้อง 9"
                      outline
                      prepend-icon="mdi-numeric-9-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                    <v-text-field
                      v-model="personInForm.room10"
                      label="เข้าห้อง 10"
                      outline
                      prepend-icon="mdi-numeric-10-box-outline"
                      hint="วัน/เดือน/พ.ศ."
                    ></v-text-field>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="grey lighten-2">
            <v-spacer></v-spacer>
            <v-btn
              elevation="2"
              color="primary"
              @click="storeNewPersonSql"
              v-if="mode == 'new' && user_info.frm_userdetail == 1"
            >
              บันทึก
            </v-btn>

            <v-btn
              dark
              color="primary"
              type="submit"
              v-if="mode == 'edit' && user_info.frm_userdetail == 1"
            >
              บันทึก
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

export default {
  name: "editFormPerson",
  components: {},
  data: () => ({
    mode: "",
    currentPreviewImg: null,
    file: null,
    fieldRules: [],
    personInForm: {},
    personBeforeChange: {},
    editId: null,
    dialog: false,
    // personDefaultForm: {
    //   jsjid: "",
    //   pname: "",
    //   fname: "",
    //   lname: "",
    //   year_of_birth: "",
    //   phone_number: "",
    //   line: "",
    //   know_from: "",
    //   occupation: "",
    //   class: "",
    //   room: "",
    //   room1: "",
    //   room2: "",
    //   room3: "",
    //   room4: "",
    //   room5: "",
    //   room6: "",
    //   room7: "",
    //   room8: "",
    //   teacher1: "",
    //   teacher2: "",
    //   province: "",
    //   status: "",
    //   remark: "",
    // },
    valid: true,
    valid4: [(v) => (v || "").length <= 4 || "จำนวนอักษรต้องไม่เกิน 4"],
    valid10: [(v) => (v || "").length <= 10 || "จำนวนอักษรต้องไม่เกิน 10"],
    valid30: [(v) => (v || "").length <= 30 || "จำนวนอักษรต้องไม่เกิน 30"],
    valid50: [(v) => (v || "").length <= 50 || "จำนวนอักษรต้องไม่เกิน 50"],
    valid100: [(v) => (v || "").length <= 100 || "จำนวนอักษรต้องไม่เกิน 100"],
    valid255: [(v) => (v || "").length <= 255 || "จำนวนอักษรต้องไม่เกิน 255"],
    validjsjid: [
      (v) => !!v || "กรุณาใส่รหัสนักศึกษา",
      // (v) => (v && v.length <= 19) || "จำนวนตัวเลขต้องไม่เกิน 19 หลัก",
      (v) =>
        (v && v >= 1 && v <= 9999999999999999999) || "ต้องเป็นตัวเลขเท่านั้น",
    ],
    jsjidErrorsMessage: "",
  }),

  methods: {
    close() {
      this.dialog = false;
      this.formInitNew();
    },

    async formInitEdit() {
      if (this.editId) {
        let container = document.getElementById("scrolling-techniques");
        container.scrollTop = 0; // not working
        this.global.loading = await true;
        await this.getPersonSql();
        this.currentPreviewImg = await this.personInForm.images;
        this.file = null;
        this.global.loading = await false;
      }
    },

    formInitNew() {
      let container = document.getElementById("scrolling-techniques");
      container.scrollTop = 0; // not working
      this.file = null;
      this.currentPreviewImg = null;
      // this.personInForm = this.personDefaultForm;
      this.emptyForm();
    },
    emptyForm() {
      this.personInForm = {
        jsjid: "",
        pname: "",
        fname: "",
        lname: "",
        year_of_birth: "",
        phone_number: "",
        line: "",
        know_from: "",
        occupation: "",
        class: "",
        room: "",
        room1: "",
        room2: "",
        room3: "",
        room4: "",
        room5: "",
        room6: "",
        room7: "",
        room71: "",
        room8: "",
        room9: "",
        room10: "",
        teacher1: "",
        teacher2: "",
        province: "",
        status: "",
        remark: "",
        randkey: "",
      };
    },

    addPersonArray(e) {
      if (e.line == "") {
        e.line = "ไม่มีไลน์";
      }
      this.person.push(e);
    },

    async updatePersonArray(e) {
      // var index = await this.person.findIndex((x) => x.id == e.id);
      var index = await this.$store.state.person.findIndex((x) => x.id == e.id);
      // const index = this.person.findIndex((object) => {
      //   return object.id == e.id;
      // });
      // await console.log(index);
      await this.$store.state.person.splice(index, 1, e);
    },

    async getPersonSql() {
      var { data } = await axios.get(
        `${this.apiurl}/api/people/${this.editId}`,
        {}
      );
      data.class =
        (await data.room) == null ? data.class : data.class + "/" + data.room;
      // await console.log(data);
      this.personBeforeChange = await Object.assign({}, data);
      this.personInForm = await data;
    },

    async storeNewPersonSql() {
      this.$store.dispatch("init_set_loading", { loading: true });
      this.jsjidErrorsMessage = await "";
      await this.$refs.form.validate();
      await this.doStoreNewPersonSql();
    },
    async doStoreNewPersonSql() {
      if (this.valid) {
        var formData = await new FormData();
        if (this.file) {
          await formData.append("image", this.file, this.file.name);
        }
        await formData.append("jsjid", this.personInForm.jsjid);
        await formData.append("pname", this.personInForm.pname);
        await formData.append("fname", this.personInForm.fname);
        await formData.append("lname", this.personInForm.lname);
        await formData.append("year_of_birth", this.personInForm.year_of_birth);
        await formData.append("phone_number", this.personInForm.phone_number);
        await formData.append("line", this.personInForm.line);
        await formData.append("know_from", this.personInForm.know_from);
        await formData.append("occupation", this.personInForm.occupation);
        await formData.append("class", this.personInForm.class);
        await formData.append("room", this.personInForm.room);
        await formData.append("room1", this.personInForm.room1);
        await formData.append("room2", this.personInForm.room2);
        await formData.append("room3", this.personInForm.room3);
        await formData.append("room4", this.personInForm.room4);
        await formData.append("room5", this.personInForm.room5);
        await formData.append("room6", this.personInForm.room6);
        await formData.append("room7", this.personInForm.room7);
        await formData.append("room71", this.personInForm.room71);
        await formData.append("room8", this.personInForm.room8);
        await formData.append("room9", this.personInForm.room9);
        await formData.append("room10", this.personInForm.room10);
        await formData.append("teacher1", this.personInForm.teacher1);
        await formData.append("teacher2", this.personInForm.teacher2);
        await formData.append("province", this.personInForm.province);
        await formData.append("status", this.personInForm.status);
        await formData.append("remark", this.personInForm.remark);

        await axios
          .post(this.person_url + "/people", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.addPersonArray(response.data);
            this.$store.dispatch("init_set_loading", { loading: false });
            this.dialog = false;
            this.formInitNew();
          })
          .catch(() => {
            //error
            // if (error.response) {
            // console.log(error.response.data.errors.jsjid[0]);
            // this.jsjidErrorsMessage = error.response.data.errors.jsjid[0];
            this.jsjidErrorsMessage =
              "รหัส " + this.personInForm.jsjid + " มีอยู่แล้ว (รหัสซ้ำ)";
            this.$store.dispatch("init_set_loading", { loading: false });
            // }
          });

        // this.dialog = await false;
      }
    },

    async updatePerson() {
      this.jsjidErrorsMessage = await "";
      await this.$refs.form.validate();
      await this.doUpdatePerson();
    },
    async doUpdatePerson() {
      if (this.valid) {
        this.$store.dispatch("init_set_loading", { loading: true });
        var formData = await new FormData();
        if (this.file) {
          await formData.append("image", this.file, this.file.name);
        }
        await formData.append("id", this.personInForm.id);
        if (this.personBeforeChange.jsjid != this.personInForm.jsjid) {
          await formData.append("jsjid", this.personInForm.jsjid);
        }
        if (this.personBeforeChange.pname != this.personInForm.pname) {
          await formData.append("pname", this.personInForm.pname);
        }
        if (this.personBeforeChange.fname != this.personInForm.fname) {
          await formData.append("fname", this.personInForm.fname);
        }
        if (this.personBeforeChange.lname != this.personInForm.lname) {
          await formData.append("lname", this.personInForm.lname);
        }
        if (
          this.personBeforeChange.year_of_birth !=
          this.personInForm.year_of_birth
        ) {
          await formData.append(
            "year_of_birth",
            this.personInForm.year_of_birth
          );
        }
        if (
          this.personBeforeChange.phone_number != this.personInForm.phone_number
        ) {
          await formData.append("phone_number", this.personInForm.phone_number);
        }
        if (this.personBeforeChange.line != this.personInForm.line) {
          await formData.append("line", this.personInForm.line);
        }
        if (this.personBeforeChange.know_from != this.personInForm.know_from) {
          await formData.append("know_from", this.personInForm.know_from);
        }
        if (
          this.personBeforeChange.occupation != this.personInForm.occupation
        ) {
          await formData.append("occupation", this.personInForm.occupation);
        }
        // ----------------------------
        if (this.personBeforeChange.class != this.personInForm.class) {
          if (this.personInForm.class.includes("/")) {
            const classSplit = this.personInForm.class.split("/");
            await formData.append("class", classSplit[0]);
            await formData.append("room", classSplit[1]);
          } else {
            await formData.append("class", this.personInForm.class);
            await formData.append("room", null);
          }
        }
        // if (this.personBeforeChange.room != this.personInForm.room) {
        // }
        // ----------------------------
        if (this.personBeforeChange.room1 != this.personInForm.room1) {
          await formData.append("room1", this.personInForm.room1);
        }
        if (this.personBeforeChange.room2 != this.personInForm.room2) {
          await formData.append("room2", this.personInForm.room2);
        }
        if (this.personBeforeChange.room3 != this.personInForm.room3) {
          await formData.append("room3", this.personInForm.room3);
        }
        if (this.personBeforeChange.room4 != this.personInForm.room4) {
          await formData.append("room4", this.personInForm.room4);
        }
        if (this.personBeforeChange.room5 != this.personInForm.room5) {
          await formData.append("room5", this.personInForm.room5);
        }
        if (this.personBeforeChange.room6 != this.personInForm.room6) {
          await formData.append("room6", this.personInForm.room6);
        }
        if (this.personBeforeChange.room7 != this.personInForm.room7) {
          await formData.append("room7", this.personInForm.room7);
        }
        if (this.personBeforeChange.room71 != this.personInForm.room71) {
          await formData.append("room71", this.personInForm.room71);
        }
        if (this.personBeforeChange.room8 != this.personInForm.room8) {
          await formData.append("room8", this.personInForm.room8);
        }
        if (this.personBeforeChange.room9 != this.personInForm.room9) {
          await formData.append("room9", this.personInForm.room9);
        }
        if (this.personBeforeChange.room10 != this.personInForm.room10) {
          await formData.append("room10", this.personInForm.room10);
        }
        if (this.personBeforeChange.teacher1 != this.personInForm.teacher1) {
          await formData.append("teacher1", this.personInForm.teacher1);
        }
        if (this.personBeforeChange.teacher2 != this.personInForm.teacher2) {
          await formData.append("teacher2", this.personInForm.teacher2);
        }
        if (this.personBeforeChange.province != this.personInForm.province) {
          await formData.append("province", this.personInForm.province);
        }
        if (this.personBeforeChange.status != this.personInForm.status) {
          await formData.append("status", this.personInForm.status);
        }
        if (this.personBeforeChange.remark != this.personInForm.remark) {
          await formData.append("remark", this.personInForm.remark);
        }

        await formData.append("randkey", this.personInForm.randkey);

        await axios
          .post(
            `${this.apiurl}/api/people/${this.editId}?_method=PUT`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            this.updatePersonArray(response.data);
            this.$store.dispatch("init_set_loading", { loading: false });
            this.dialog = false;
            this.formInitNew();
          })
          .catch(() => {
            //error
            // if (error.response) {
            // console.log(error.response.data.errors.jsjid[0]);
            // this.jsjidErrorsMessage = error.response.data.errors.jsjid[0];
            this.jsjidErrorsMessage =
              "รหัส " + this.personInForm.jsjid + " มีอยู่แล้ว (รหัสซ้ำ)";
            this.$store.dispatch("init_set_loading", { loading: false });
            // }
          });
      }

      // await this.updatePersonArray(data);
      // this.$store.dispatch("init_set_loading", { loading: false });
      // this.dialog = await false;
      // await this.formInitNew();
    },

    async storeImage(picid) {
      if (this.file) {
        var formData = await new FormData();
        await formData.append("id", picid);
        // await formData.append("token", localStorage.getItem("token"));
        await formData.append("image", this.file, this.file.name);
        await axios.post(`${this.apiurl}/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    },

    preview_image() {
      if (this.file) {
        this.currentPreviewImg = URL.createObjectURL(this.file);
        URL.revokeObjectURL(this.file);
      } else {
        this.currentPreviewImg = null;
        this.file = null;
      }
    },

    async backToOldImage() {
      this.currentPreviewImg = await this.personInForm.image;
    },
    init() {
      this.$store.dispatch("init_teacher_full", { type: 3 });
      this.$store.dispatch("init_know_from_full", { type: 4 });
    },
  },
  computed: {
    ...mapState(["user_info"]),
    mode_title() {
      return this.mode == "new" ? "เพิ่มนักศึกษาใหม่" : "ข้อมูลนักศึกษา";
    },

    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
    apiurl() {
      return this.$store.state.apiurl;
    },
  },
  mounted() {},
  created() {
    // this.init();
  },
};
</script>

<style></style>
