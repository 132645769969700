<template>
  <v-app>
    <v-snackbar top :value="updateExists" :timeout="-1" color="primary">
      ตรวจพบการ Update
      <template v-slot:action="{ attrs }">
        <v-btn dark outlined v-bind="attrs" @click="refreshApp">
          กดที่นี่เพื่อ Update
        </v-btn>
      </template>
    </v-snackbar>
    <v-main v-if="$store.state.user_info.is_login === false"><login /></v-main>
    <v-main v-else-if="$store.state.user_info.is_login === true">
      <v-overlay :value="$store.state.global.loading" z-index="999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <navigation />
      <appbar />
      <router-view></router-view>
    </v-main>
    <v-main v-else>
      <div class="centerlogin"><h1>กรุณารอสักครู่...</h1></div>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import login from "@/components/login";
import navigation from "@/components/navigation";
import appbar from "@/components/appBar/appbar";
import update from "./mixins/update";

export default {
  components: {
    login,
    navigation,
    appbar,
  },
  data: () => ({}),
  mixins: [update],
  methods: {},
  computed: {},
  mounted() {
    axios
      .get(`${this.$store.state.apiurl}/api/current`)
      .then((response) => {
        if (response.data && response.data.c === true) {
          this.$store.state.user_info.is_login = true;
          this.$store.state.user_info.username = response.data.username;
          this.$store.state.user_info.role = response.data.role;
          this.$store.state.user_info.room = response.data.room;
          this.$store.state.user_info.frm_userdetail =
            response.data.frm_userdetail;
          this.$store.state.user_info.frm_check = response.data.frm_check;
          this.$store.state.current_room = response.data.room[0];
        } else {
          this.$store.state.user_info.is_login = false;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status !== 200) {
          this.$store.state.user_info.is_login = false;
        }
      });
  },
};
</script>

<style>
.centerlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: dodgerblue;
}

html {
  overflow-y: auto !important;
}

/* width */
::-webkit-scrollbar {
  /* overflow: hidden; */
  /* display: hidden; */
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#vuetifyDataTable th {
  background-color: #e0e0e0;
  font-size: 0.95rem;
  height: 32px;
}

#vuetifyDataTable td {
  font-size: 0.95rem;
  height: 32px;
}
</style>
