var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name != 'home')?_c('v-app-bar',{attrs:{"dense":"","flat":"","app":""},scopedSlots:_vm._u([(_vm.$route.name == 'checkroom')?{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"grow":"","color":"teal lighten-5","active-class":"teal darken-5","hide-slider":""},model:{value:(_vm.global.check_tab_mode),callback:function ($$v) {_vm.$set(_vm.global, "check_tab_mode", $$v)},expression:"global.check_tab_mode"}},[_c('v-tab',[_vm._v("อยู่ระหว่างการตรวจ")]),_c('v-tab',[_vm._v("น.ศ. ที่ได้เลือกไว้")])],1)]},proxy:true}:null],null,true)},[(!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.handle_drawer()}}}):_vm._e(),(
        _vm.$route.name == 'db' &&
        (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)
      )?_c('v-btn',{attrs:{"to":"/","icon":""}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1):_vm._e(),(
        _vm.$route.name == 'checkroom' &&
        (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)
      )?_c('v-btn',{attrs:{"to":"/checkmenu","icon":""}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1):_vm._e(),(
        _vm.$route.name == 'checkmenu' &&
        (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)
      )?_c('v-btn',{attrs:{"to":"/","icon":""}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1):_vm._e(),_c('v-spacer'),(_vm.$route.name == 'checkroom')?_c('div',[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.roomtitle))])],1):_vm._e(),(_vm.$route.name == 'checkmenu')?_c('div',[_c('v-toolbar-title',[_vm._v(" เลือกห้องเพื่อตรวจวาระจิต")])],1):_vm._e(),(_vm.$route.name == 'db' && !_vm.$vuetify.breakpoint.xs)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeView(1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-comfy")])],1)]}}],null,false,2016817794)},[_c('span',[_vm._v("มุมมองรูปภาพ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeView(2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-grid-outline")])],1)]}}],null,false,2873490282)},[_c('span',[_vm._v("มุมมองการ์ด")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeView(3)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-sequential-outline")])],1)]}}],null,false,1224698714)},[_c('span',[_vm._v("มุมมองตาราง")])])],1):_vm._e(),(_vm.$route.name == 'db')?_c('v-text-field',{attrs:{"dense":"","flat":"","hide-details":"","rounded":"","solo-inverted":"","prepend-inner-icon":"mdi-magnify","clearable":"","label":"ค้นหา..."},on:{"click:clear":function($event){return _vm.handleFilter('')}},nativeOn:{"change":function($event){return _vm.handleFilter($event.target.value)}}}):_vm._e(),_c('div',[(_vm.$route.name == 'db')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.refresh_action()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3639762695)},[_c('span',[_vm._v("Refresh")])]):_vm._e()],1),_c('v-spacer'),(
        _vm.$route.name == 'db' &&
        _vm.user_info.frm_userdetail == 1 &&
        !_vm.$vuetify.breakpoint.sm &&
        !_vm.$vuetify.breakpoint.xs
      )?_c('v-btn',{staticClass:"mr-5",attrs:{"rounded":"","dark":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.newPersonClick()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" นักศึกษาใหม่ ")],1):_vm._e(),(!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm)?_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"grey darken-1","size":"38"}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1)]}}],null,false,134075512)},[_c('v-card',[_c('v-list',_vm._l((_vm.dropDownItem),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.menuActionClick(item.action)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.handle_drawer()}}}):_vm._e()],1):_vm._e(),_c('editFormPerson',{ref:"editFormPerson"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }