import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiurl: "http://localhost/jsjlaravel1/public",
    // apiurl: "https://www.jsjdatabase.com/api/public",
    apiurl: "https://www.jsjlotusdata.com/api/public",
    person: [],
    current_room: "",
    current_page: 1,
    ip: "0.0.0.0",
    user_info: {
      is_login: null,
      username: "",
      role: "",
      room: [],
      frm_userdetail: null,
      frm_check: null
    },
    global: {
      loading: false,
      view_type: null,
      drawer: null,
      refresh_trigger: true,
      status_full: [],
      teacher_full: [],
      know_from_full: [],
      total_page: 1,
      total_record: 0,
      check_tab_mode: 0
    },
    query_option: {
      id: "",
      keyword_search: "",
      class_room_tick: [],
      status_tick: [],
      line_tick: true,
      no_line_tick: true,
      other_class_room_tick: null,
      other_status_tick: null
    }
  },
  mutations: {
    set_person(state, person) {
      state.person = person;
    },
    set_loading(state, loading) {
      state.global.loading = loading;
    },
    set_user_info(state, value) {
      state.user_info = value.user_info;
    },
    set_global(state, value) {
      state.global = value.global;
    },
    set_teacher_full(state, data) {
      state.global.teacher_full = data;
    },
    set_know_from_full(state, data) {
      state.global.know_from_full = data;
    },
    set_query_option(state, value) {
      state.query_option = value.query_option;
    }
  },
  getters: {
    get_line_full(state) {
      return state.line_full;
    },
    get_person(state) {
      return state.person;
    },
    get_user_info(state) {
      return state.user_info;
    },
    get_global(state) {
      return state.global;
    },
    get_query_option(state) {
      return state.query_option;
    }
  },
  actions: {
    handle_person({ commit }, person) {
      commit("set_person", person.person);
    },

    handle_user_info({ commit }, user_info) {
      commit("set_user_info", user_info);
    },

    handle_global({ commit }, global) {
      commit("set_global", global);
    },
    handle_query_option({ commit }, query_option) {
      commit("set_query_option", query_option);
    },

    async init_class_room_full(context, { type }) {
      const { data } = await axios.get(`${this.state.apiurl}/api/room`, {
        params: {
          type: type
        }
      });
      context.commit("set_class_room_full", data);
    },

    async init_class_room_full_key(context, { type }) {
      const { data } = await axios.get(`${this.state.apiurl}/api/roomkey`, {
        params: {
          type: type
        }
      });
      context.commit("set_class_room_full_key", data);
    },

    async init_class_room_full_val(context, { type }) {
      const { data } = await axios.get(`${this.state.apiurl}/api/roomval`, {
        params: {
          type: type
        }
      });
      context.commit("set_class_room_full_val", data);
    },

    async init_know_from_full(context, { type }) {
      const { data } = await axios.get(`${this.state.apiurl}/api/listforselect`, {
        params: {
          type: type
        }
      });
      context.commit("set_know_from_full", data);
    },

    async init_teacher_full(context, { type }) {
      const { data } = await axios.get(`${this.state.apiurl}/api/listforselect`, {
        params: {
          type: type
        }
      });
      context.commit("set_teacher_full", data);
    },

    async init_set_loading(context, { loading }) {
      await context.commit("set_loading", loading);
    },

    async fetch_person(context) {
      await context.commit("set_loading", true);
      await context.commit("set_person", []);
      let status = await [];
      if (this.getters.get_query_option.status_tick.length > 0) {
        status = await this.getters.get_query_option.status_tick;
      }
      var { data } = await axios.get(`${this.state.apiurl}/api/people`, {
        params: {
          room: this.state.current_room,
          // "room": room,
          page: this.state.current_page,
          keyword: this.getters.get_query_option.keyword_search,
          status: status,
          statusother: this.getters.get_query_option.other_status_tick,
          line: this.getters.get_query_option.line_tick,
          noline: this.getters.get_query_option.no_line_tick
        },
        headers: {
          "Content-type": "application/json"
        }
      });

      for (let i = 0; i < data.data.length; i++) {
        data.data[i]["randomid"] = await Math.floor(Math.random() * 1000);
      }

      this.state.global.total_record = await data.total;
      this.state.global.total_page = await data.last_page;
      this.state.person = await data.data;
      this.state.global.loading = await false;
    },

    async logout(context) {
      await axios.post(`${this.state.apiurl}/api/logout`, {});
      await context.commit("set_user_info", {
        is_login: false,
        username: "",
        role: "",
        room: ""
      });
      await localStorage.removeItem("token");
      await window.location.reload();
    }
  },
  modules: {}
});
