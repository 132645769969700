<template>
  <div>
    <v-app-bar dense flat app v-if="$route.name != 'home'">
      <!-- APP NAVBAR ICON LEFT-->
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        @click="handle_drawer()"
      ></v-app-bar-nav-icon>
      <!-- {{ $route.name }} -->
      <!-- ------------------------------------------------------------------------------------------------------------------ -->

      <!-- BACK ICON-->
      <v-btn
        to="/"
        v-if="
          $route.name == 'db' &&
          ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
        "
        icon
      >
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <!-- <v-container class="py-0 fill-height"> -->
      <v-btn
        to="/checkmenu"
        v-if="
          $route.name == 'checkroom' &&
          ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
        "
        icon
      >
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <v-btn
        to="/"
        v-if="
          $route.name == 'checkmenu' &&
          ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
        "
        icon
      >
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>

      <!-- ------------------------------------------------------------------------------------------------------------------ -->
      <v-spacer></v-spacer>
      <!-- VIEW ICON-->

      <div v-if="$route.name == 'checkroom'">
        <v-toolbar-title> {{ roomtitle }}</v-toolbar-title>
      </div>
      <div v-if="$route.name == 'checkmenu'">
        <v-toolbar-title> เลือกห้องเพื่อตรวจวาระจิต</v-toolbar-title>
      </div>
      <div v-if="$route.name == 'db' && !$vuetify.breakpoint.xs">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="changeView(1)">
              <v-icon>mdi-view-comfy</v-icon>
            </v-btn>
          </template>
          <span>มุมมองรูปภาพ</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="changeView(2)">
              <v-icon>mdi-view-grid-outline</v-icon>
            </v-btn>
          </template>
          <span>มุมมองการ์ด</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="changeView(3)">
              <v-icon>mdi-view-sequential-outline</v-icon>
            </v-btn>
          </template>
          <span>มุมมองตาราง</span>
        </v-tooltip>

        <!-- <v-tooltip bottom v-if="global.view_type == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="global.view_type = 1"
              >
                <v-icon>mdi-view-grid-outline</v-icon>
              </v-btn>
            </template>
            <span>มุมมองรูปภาพ</span>
          </v-tooltip>

          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-view-grid-outline</v-icon>
              </v-btn>
            </template>
            <span>มุมมองรูปภาพ</span>
          </v-tooltip>

          <v-tooltip bottom v-if="global.view_type == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="global.view_type = 2"
              >
                <v-icon>mdi-view-sequential-outline</v-icon>
              </v-btn>
            </template>
            <span>มุมมองตาราง</span>
          </v-tooltip>

          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-view-sequential-outline</v-icon>
              </v-btn>
            </template>
            <span>มุมมองตาราง</span>
          </v-tooltip> -->
      </div>

      <!-- ------------------------------------------------------------------------------------------------------------------ -->

      <!-- SEARCH PANEL-->
      <!-- <v-spacer></v-spacer> -->
      <!-- <div> -->
      <!-- <v-responsive v-if="$route.name == 'db'"> -->
      <v-text-field
        v-if="$route.name == 'db'"
        dense
        flat
        hide-details
        rounded
        solo-inverted
        prepend-inner-icon="mdi-magnify"
        clearable
        @click:clear="handleFilter('')"
        label="ค้นหา..."
        @change.native="handleFilter($event.target.value)"
      ></v-text-field>
      <!-- </v-responsive> -->
      <!-- </div> -->
      <div>
        <v-tooltip bottom v-if="$route.name == 'db'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="refresh_action()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </div>
      <!-- <v-tooltip bottom v-if="$route.name == 'db'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="search_settings()">
              <v-icon>mdi-table-settings</v-icon>
            </v-btn>
          </template>
          <span>ตั้งค่าคอลัมน์ของตาราง</span>
        </v-tooltip> -->

      <!-- ------------------------------------------------------------------------------------------------------------------ -->
      <v-spacer></v-spacer>
      <!-- </v-container> -->
      <!-- ADD NEW ICON-->

      <v-btn
        v-if="
          $route.name == 'db' &&
          user_info.frm_userdetail == 1 &&
          !$vuetify.breakpoint.sm &&
          !$vuetify.breakpoint.xs
        "
        rounded
        class="mr-5"
        dark
        outlined
        @click="newPersonClick()"
        color="primary"
      >
        <v-icon left>mdi-account-plus</v-icon>
        นักศึกษาใหม่
      </v-btn>
      <!-- <download-excel :data="person" worksheet="db" name="db.xls">
        <v-btn></v-btn>
      </download-excel> -->
      <!-- ------------------------------------------------------------------------------------------------------------------ -->

      <!-- PROFILE ICON-->

      <div
        class="text-center"
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="grey darken-1" size="38" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-account</v-icon>
            </v-avatar>
          </template>

          <v-card>
            <!-- <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ user_info.first_name }} {{ user_info.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user_info.position }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->

            <!-- <v-divider></v-divider> -->

            <v-list>
              <v-list-item
                link
                v-for="(item, index) in dropDownItem"
                :key="index"
                @click="menuActionClick(item.action)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>

      <!-- ------------------------------------------------------------------------------------------------------------------ -->

      <!-- APP NAVBAR ICON RIGHT-->
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        @click="handle_drawer()"
      ></v-app-bar-nav-icon>

      <!-- ------------------------------------------------------------------------------------------------------------------ -->

      <!-- tabs check room-->
      <template v-slot:extension v-if="$route.name == 'checkroom'">
        <v-tabs
          v-model="global.check_tab_mode"
          grow
          color="teal lighten-5"
          active-class="teal darken-5"
          hide-slider
        >
          <v-tab>อยู่ระหว่างการตรวจ</v-tab>
          <v-tab>น.ศ. ที่ได้เลือกไว้</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <!-- ------------------------------------------------------------------------------------------------------------------ -->
    <editFormPerson ref="editFormPerson" />
    <!-- <passwordEditForm ref="passwordEditForm" /> -->
    <!-- <searchSettingsDialog ref="searchSettingsDialog" /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import editFormPerson from "@/components/editFormPerson";
// import passwordEditForm from "@/components/passwordEditForm";
// import searchSettingsDialog from "@/components/searchSettingsDialog";
// import { mapState } from "vuex";
export default {
  name: "appBar",
  components: {
    editFormPerson,
    // searchSettingsDialog,
    // passwordEditForm,
  },
  data: () => ({
    dropDownItem: [
      // {
      //   title: "แก้ไขรหัสผ่าน",
      //   icon: "mdi-form-textbox-password",
      //   action: "profileEdit",
      // },
      { title: "ออกจากระบบ", icon: "mdi-logout-variant", action: "logout" },
    ],
  }),
  methods: {
    changeView(input) {
      this.global.view_type = input;
      localStorage.view_type = input;
    },
    async newPersonClick() {
      // if (this.user_info.level == "0" || this.user_info.level == "1") {
      await this.$refs.editFormPerson.init();
      this.$refs.editFormPerson.mode = await "new";
      this.$refs.editFormPerson.dialog = await true;
      await this.$refs.editFormPerson.formInitNew();
      // }
    },
    search_settings() {
      this.$refs.searchSettingsDialog.dialog = true;
    },
    fetch_person() {
      this.$store.dispatch("fetch_person");
    },
    refresh_action() {
      this.fetch_person();
    },
    handle_drawer() {
      this.global.drawer = !this.global.drawer;
    },
    menuActionClick(action) {
      if (action === "profileEdit") {
        this.$refs.passwordEditForm.dialog = true;
      } else if (action === "logout") {
        this.$store.dispatch("logout");
      }
    },
    async handleFilter(keyword) {
      this.$store.state.query_option.current_page = await 1;
      this.query_option.keyword_search = await keyword;
    },
  },
  computed: {
    ...mapState(["person"]),
    roomtitle() {
      let rere = "";
      switch (this.$route.params.room) {
        case "1":
          rere = "หลักสูตร 1 ขึ้น หลักสูตร 2";
          break;
        case "2":
          rere = "หลักสูตร 2 ขึ้น หลักสูตร 3";
          break;
        case "3":
          rere = "หลักสูตร 3 ขึ้น หลักสูตร 4";
          break;
        case "4":
          rere = "หลักสูตร 4 ขึ้น หลักสูตร 5";
          break;
        case "5":
          rere = "หลักสูตร 5 ขึ้น หลักสูตร 6";
          break;
        case "6":
          rere = "หลักสูตร 6 ขึ้น หลักสูตร 7";
          break;
        case "7":
          rere = "หลักสูตร 7 ขึ้น หลักสูตร 7.10";
          break;
        case "7.10":
          rere = "หลักสูตร 7.10 ขึ้น หลักสูตร 8";
          break;
        default:
          rere = "...";
      }
      return rere;
    },
    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
    user_info: {
      get() {
        return this.$store.getters.get_user_info;
      },
      set(value) {
        this.$store.dispatch("handle_user_info", {
          userInfo: value,
        });
      },
    },
    query_option: {
      get() {
        return this.$store.getters.get_query_option;
      },
      set(value) {
        this.$store.dispatch("handle_query_option", {
          query_option: value,
        });
      },
    },
  },
  mounted() {},
  watch: {
    $vuetify: {
      handler() {
        if (this.$vuetify.breakpoint.name == "xs") {
          this.global.view_type = 2;
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
