<template>
  <v-navigation-drawer
    app
    v-model="global.drawer"
    color="grey lighten-5"
    :right="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
  >
    <v-sheet color="grey lighten-5" class="pa-4">
      <v-img :src="require('@/assets/logo.svg')"></v-img>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet>
      <v-list nav dense>
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>หน้าหลัก</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/db" link>
          <v-list-item-icon>
            <v-icon>mdi-database</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ฐานข้อมูลนักศึกษา</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/datagrid" link v-if="user_info.role <= 1">
          <v-list-item-icon>
            <v-icon>mdi-database</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Datagrid</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/checkmenu" link>
          <v-list-item-icon>
            <v-icon>mdi-account-star</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ตรวจวาระจิต</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="gotonext" link v-if="user_info.role <= 2">
          <v-list-item-icon>
            <v-icon>mdi-page-next-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ผลการตรวจวาระจิต</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/report" link>
          <v-list-item-icon>
            <v-icon>mdi-chart-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>จำนวนนักศึกษา</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="import" link v-if="user_info.role <= 2">
          <v-list-item-icon>
            <v-icon>mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>นำเข้าข้อมูล</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="upimg" link v-if="user_info.role <= 2">
          <v-list-item-icon>
            <v-icon>mdi-camera</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>นำเข้าภาพ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item to="setting" link v-if="user_info.role <= 1">
          <v-list-item-icon>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ตั้งค่า</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item to="user" link v-if="user_info.role <= 1">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>จัดการผู้ใช้งาน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="token" link v-if="user_info.role <= 1">
          <v-list-item-icon>
            <v-icon>mdi-ticket-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ผู้ที่เข้าระบบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
    <div v-if="$route.name == 'db'">
      <v-divider></v-divider>
      <v-sheet color="grey lighten-5" class="px-2">
        <v-list dense>
          <v-list-item
            link
            v-for="(room, index) of $store.state.user_info.room"
            :key="index"
            @click="selectsubroom(room)"
            :class="$store.state.current_room == room ? 'grey lighten-2' : ''"
          >
            <v-list-item-icon v-if="$store.state.current_room == room">
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <v-icon></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ classOrRoom(room) }} {{ room }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet color="grey lighten-5" class="pa-4">
        <div class="mb-2">สถานะ</div>
        <v-checkbox
          v-for="(status, index) in global.status_full"
          :key="index"
          v-model="query_option.status_tick"
          :value="status"
          :label="status"
          color="primary"
          hide-details
          class="ma-0 pa-0"
        />
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet color="grey lighten-5" class="pa-4">
        <div class="my-2">ไลน์</div>
        <v-checkbox
          v-model="query_option.line_tick"
          label="มีไลน์"
          color="primary"
          hide-details
          class="ma-0 pa-0"
        />
        <v-checkbox
          v-model="query_option.no_line_tick"
          label="ไม่มีไลน์"
          color="primary"
          hide-details
          class="ma-0 pa-0"
        />
      </v-sheet>
      <v-divider></v-divider>
    </div>
    <div v-if="$route.name == 'checkroom' || $route.name == 'checkmenu'">
      <v-divider></v-divider>
      <v-sheet class="blue-grey lighten-5">
        <v-list dense nav>
          <v-list-item to="/checkroom/2" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-2-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 2</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/3" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-3-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 3</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/4" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-4-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 4</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/5" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-5-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 5</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/6" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-6-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 6</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/7" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-7-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 7</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/checkroom/7.10" link>
            <v-list-item-icon>
              <v-icon>mdi-numeric-7-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>หลักสูตร 7.10</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-divider></v-divider>
    </div>
    <div>
      <v-sheet color="grey lighten-5" class="pa-4">
        <v-btn block @click="logout()">
          <v-icon left>mdi-logout-variant</v-icon>
          ออกจากระบบ
        </v-btn>
      </v-sheet>
    </div>
    <editFormPerson ref="editFormPerson" />
  </v-navigation-drawer>
</template>

<script>
import editFormPerson from "@/components/editFormPerson";
export default {
  name: "navigation",
  components: {
    editFormPerson,
  },
  data: () => ({
    showSubRoom: false,
  }),
  methods: {
    classOrRoom(room) {
      let re = "";
      if (room == "Mentor" || room == "ALL") {
        re = "";
      } else if (room.includes("/")) {
        re = "ห้อง";
      } else {
        re = "หลักสูตร";
      }
      return re;
    },

    async selectsubroom(room) {
      this.$store.state.current_room = await room;
      this.$store.state.current_page = await 1;
      if (this.global.loading != true) {
        await this.$store.dispatch("fetch_person");
      }
    },

    logout() {
      this.$store.dispatch("logout");
    },
    async newPersonClick() {
      // if (this.user_info.level == "0" || this.user_info.level == "1") {
      this.$refs.editFormPerson.dialog = await true;
      this.$refs.editFormPerson.mode = await "new";
      await this.$refs.editFormPerson.formInitNew();
      // }
    },
  },
  computed: {
    // ...mapState(["user_info"]),
    user_info: {
      get() {
        return this.$store.getters.get_user_info;
      },
      set(value) {
        this.$store.dispatch("handle_user_info", {
          user_info: value,
        });
      },
    },
    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
    query_option: {
      get() {
        return this.$store.getters.get_query_option;
      },
      set(value) {
        this.$store.dispatch("handle_query_option", {
          query_option: value,
        });
      },
    },
  },
  created() {
    // console.log(this.$store.state.user_info.room);
  },
  watch: {
    showSubRoom: {
      handler() {
        if (this.showSubRoom) {
          this.user_info.room = this.global.class_room_full;
        } else {
          this.user_info.room = this.global.class_room_full_key;
        }
      },
      deep: true,
    },
    query_option: {
      handler() {
        this.$store.state.current_page = 1;
        if (this.global.loading != true) {
          this.$store.dispatch("fetch_person");
          localStorage.status_tick = JSON.stringify(
            this.query_option.status_tick
          );
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
